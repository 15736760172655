import React, { useState, useEffect } from "react";
import AADService from "../../auth/authFunctions";
import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import "./OperacionesSQL.css";

const CargueMasivo = ({ setMensajeCarga, setErrorCarga }) => {
    const [archivo, setArchivo] = useState(null);
    const [tipoConsulta, setTipoConsulta] = useState("");
    const [columnasWhere, setColumnasWhere] = useState([]);
    const [columnasSet, setColumnasSet] = useState([]);
    const [datosJSON, setDatosJSON] = useState([]);
    const [archivoResultados, setArchivoResultados] = useState(""); // Guardar URL de archivo
    const [loading, setLoading] = useState("Ejecutar Carga");
    const serviceAAD = new AADService();
    const user = serviceAAD.getUser();

    // Manejo de archivo y conversión a JSON
    const handleArchivo = (event) => {
        const file = event.target.files[0];
        if (file) {
            setArchivo(file);
            procesarExcel(file);
        }
    };

    const procesarExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            setDatosJSON(jsonData);
        };
        reader.readAsArrayBuffer(file);
    };

    //  Manejo de tipo de consulta
    const handleTipoConsulta = (event) => {
        setTipoConsulta(event.target.value);
        if (event.target.value === "INSERT INTO") {
            setColumnasWhere([]);
            setColumnasSet([]);
        }
    };

    //  Manejo de columnas WHERE y SET en UPDATE
    const handleColumnasWhere = (event) => {
        setColumnasWhere(event.target.value.split(",").map(col => col.trim()));
    };

    const handleColumnasSet = (event) => {
        setColumnasSet(event.target.value.split(",").map(col => col.trim()));
    };

    // Enviar datos al backend
    const enviarDatos = async () => {
        if (!archivo) {
            setErrorCarga("Debe seleccionar un archivo");
            return;
        }

        setLoading("Realizando Consulta...")
        setMensajeCarga(null);
        setErrorCarga(null);

        const payload = {
            usuario_modificador: user.userName,
            tipo_consulta: tipoConsulta,
            columnas_set: tipoConsulta === "UPDATE" ? columnasSet : [],
            columnas_where: tipoConsulta === "UPDATE" ? columnasWhere : [],
            datos: datosJSON,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/cargaMasiva/`, {
                method: "POST",
                body: JSON.stringify(payload),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: "Bearer " + serviceAAD.getToken(),
                },
            });

            const responseData = await response.json();
            setLoading("Ejecutar Carga")

            if (response.ok) {
                setMensajeCarga("✅ Carga completada exitosamente. Puede descargar los resultados.");
                setArchivoResultados(responseData.archivo); // Se guarda la URL del archivo

                setArchivo(null);
                setDatosJSON([]);
                setTipoConsulta("");
                setColumnasWhere([]);
                setColumnasSet([]);
            } else {
                let errorMsg = responseData.error || responseData.mensaje || "Error desconocido en la carga.";

                if(errorMsg.includes("m_querylog")){
                    errorMsg = "🚨 No está permitido modificar la tabla de logs `m_querylog`.";
                }
                setArchivoResultados(responseData.archivo);
                setErrorCarga(errorMsg);
            }
        } catch (error) {
            setErrorCarga("🚨 No se pudo conectar con el servidor. Intente más tarde.");
        }
    };

    //  Deshabilitar el botón si:
    const botonDeshabilitado = 
        !archivo || 
        tipoConsulta === "" || 
        (tipoConsulta === "UPDATE" && (columnasWhere.length === 0 || columnasSet.length === 0));

    async function descargaSOX() {
        let urlConsulta = process.env.REACT_APP_API_URL+ "/download/eventos/Resultados_cargaMasiva_ConsultaSQL.xlsx";
        try {
            const response = await fetch(urlConsulta, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + serviceAAD.getToken(),
            },
            });

            if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = "Resultados_cargaMasiva_ConsultaSQL.xlsx";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            } else {
            console.log("Error al descargar el archivo.");
            }
        } catch (error) {
            console.log("No se pudo conectar con el servidor. Intente más tarde.");
        }
        
        }
    return (
        <div className="contenedor-operaciones-sql">
            <h2 className="titulo-operaciones">Carga Masiva de Datos</h2>

            <div className="subir-archivo">
                <input type="file" accept=".xlsx, .xls" onChange={handleArchivo} />
            </div>

            <select className="select-consulta" value={tipoConsulta} onChange={handleTipoConsulta}>
                <option value="">Seleccione Tipo de Consulta</option>
                <option value="INSERT INTO">INSERT INTO</option>
                <option value="UPDATE">UPDATE</option>
            </select>

            {tipoConsulta === "UPDATE" && (
                <div className="contenedor-columnas">
                    <div className="grupo-input">
                        <label className="label-input">Columna WHERE:</label>
                        <input
                            type="text"
                            placeholder="Columnas WHERE (separadas por comas)"
                            className="input-columnas"
                            onChange={handleColumnasWhere}
                        />
                    </div>
            
                    <div className="grupo-input">
                        <label className="label-input">Columna SET:</label>
                        <input
                            type="text"
                            placeholder="Columnas SET (separadas por comas)"
                            className="input-columnas"
                            onChange={handleColumnasSet}
                        />
                    </div>
                </div>
            )}

            <Button 
                className="btn-ejecutar" 
                onClick={enviarDatos} 
                disabled={botonDeshabilitado}
            >
                {loading}
            </Button>

            {archivoResultados && (
                <a>
                    <Button className="btn-descargar" variant="primary" style={{ marginTop: "10px" }} onClick={() => descargaSOX()}>
                        📥 Descargar Resultados
                    </Button>
                </a>
            )}
        </div>
    );
};

export default CargueMasivo;