import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";

function ModalEliminarEfecto({
  openModal,
  setOpenModal,
  desasociarEfectos,
  tipoDeEfecto,
  setSelected_propios,
  setSelected_desencadenados,
  setSelected_recibidos,
  setLoadingResumen,
  sendData,  
}) {
  const [isEfectoDesasociado, setIsEfectoDesasociado] = useState(false); // Nuevo estado

  const CloseModal = () => setOpenModal(false);

  // Cuando se desasocia el efecto, actualiza el estado
  const handleDesasociar = async () => {
    await desasociarEfectos();  // Espera que desasociarEfectos termine
    setIsEfectoDesasociado(true);  // Marca que se ha desasociado
  };

  // Usa useEffect para ejecutar sendData después del re-render
  useEffect(() => {
    if (isEfectoDesasociado) {
      setLoadingResumen(true)
      sendData();  // Ejecuta sendData después del re-render
      setIsEfectoDesasociado(false); // Resetear el estado
    }
  }, [isEfectoDesasociado, sendData]);  // Este efecto se ejecuta cuando isEfectoDesasociado cambia

  return (
    <Modal
      size="sm"
      centered={true}
      show={openModal}
      onHide={CloseModal}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          ¿Está seguro de eliminar el efecto seleccionado?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>Este cambio se realizará de inmediato.</Modal.Body>
      <Modal.Footer>
        <Row className="mb-3 mt-4 justify-content-center">
          <Col sm={12} xs={12} className="text-center">
            <Button
              type="button"
              className="btn botonNegativo2"
              onClick={CloseModal}
            >
              Cerrar
            </Button>
            <Button
              type="button"
              className="btn botonPositivo2"
              onClick={handleDesasociar}  
            >
              Continuar
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export { ModalEliminarEfecto };
