import React, { useState } from "react";
import CargueMasivo from "./CargueMasivo";
import Contingencia from "./Contingencia";
import { Alert } from "react-bootstrap";
import "./OperacionesSQL.css";

const ConsultasSQL = () => {
  const [modo, setModo] = useState("contingencia");
  const [mensajeCarga, setMensajeCarga] = useState(null);
  const [errorCarga, setErrorCarga] = useState(null);
  const [mensajeContingencia, setMensajeContingencia] = useState(null);
  const [errorContingencia, setErrorContingencia] = useState(null);

  const mostrarMensajeCarga = (mensaje) => {
    setMensajeCarga(mensaje);
    setTimeout(() => setMensajeCarga(null), 8000);
  };

  const mostrarErrorCarga = (mensaje) => {
    setErrorCarga(mensaje);
    setTimeout(() => setErrorCarga(null), 8000);
  };

  const mostrarMensajeContingencia = (mensaje) => {
    setMensajeContingencia(mensaje);
    setTimeout(() => setMensajeContingencia(null), 8000);
  };

  const mostrarErrorContingencia = (mensaje) => {
    setErrorContingencia(mensaje);
    setTimeout(() => setErrorContingencia(null), 8000);
  };

  return (
    <div className="consultas-container">
      <h1>Operaciones SQL Masivas</h1>

      {mensajeCarga && <Alert variant="success">{mensajeCarga}</Alert>}
      {errorCarga && <Alert variant="danger">{errorCarga}</Alert>}
      {mensajeContingencia && <Alert variant="success">{mensajeContingencia}</Alert>}
      {errorContingencia && <Alert variant="danger">{errorContingencia}</Alert>}
      
      <div className="select-consulta">
      <button 
          className={`btn-template ${modo === "contingencia" ? "btn-activo" : ""}`} 
          onClick={() => setModo("contingencia")}
        >
          Consulta SQL
        </button>

        <button 
          className={`btn-cargue ${modo === "cargue" ? "btn-activo" : ""}`} 
          onClick={() => setModo("cargue")}
        >
          Cargue Masivo SQL
        </button>
      </div>

      <div className="contenido">
      {modo === "cargue" && (
          <CargueMasivo 
            setMensajeCarga={mostrarMensajeCarga} 
            setErrorCarga={mostrarErrorCarga} 
          />
        )}
        {modo === "contingencia" && (
          <Contingencia 
            setMensajeContingencia={mostrarMensajeContingencia} 
            setErrorContingencia={mostrarErrorContingencia} 
          />
        )}
      </div>
    </div>
  );
};

export default ConsultasSQL;
