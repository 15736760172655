import React, { useState, useEffect } from "react";
import "./OperacionesSQL.css";
import AADService from "../../auth/authFunctions";

const Contingencia = ({ setMensajeContingencia, setErrorContingencia }) => {
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false); // Estado de carga
    const serviceAAD = new AADService();

    // Validar la consulta en tiempo real
    const validarConsulta = (consulta) => {
        const consultaUpper = consulta.toUpperCase();
        if (consultaUpper.includes("DELETE") || consultaUpper.includes("DROP")) {
            setErrorContingencia("⚠️ Las consultas DELETE y DROP están restringidas.");
            return false;
        }

        const regexUpdate = /^UPDATE\s+[`]?[\w-]+[`]?\s+SET\s+(([`]?[\w-]+[`]?\s*=\s*[^,]+,?\s*)+)\s+WHERE\s+(([`]?[\w-]+[`]?\s*(=|IN|LIKE)\s*.+,?\s*)+)$/i;
        
        if (consultaUpper.startsWith("UPDATE") && !regexUpdate.test(consulta)) {
            setErrorContingencia(
                "Las consultas UPDATE deben contener una cláusula SET y WHERE correctamente escrita (Ejemplo: SET campo = valor, WHERE idUsuario = 1)."
            );
            return false;
        }

        setErrorContingencia(null);
        return true;
    };

    const handleChangeQuery = (e) => {
        setQuery(e.target.value);
    };

    //  Manejo de la ejecución de la consulta
    const handleEjecutar = async () => {
        if (!validarConsulta(query)) return;

        setLoading(true);
        setMensajeContingencia(null);
        setErrorContingencia(null);

        const data = {
            usuario_modificador: serviceAAD.getUser().userName,
            query: query,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/contingencia/`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + serviceAAD.getToken(),
                },
            });

            const responseData = await response.json();

            if (response.ok) {
                setMensajeContingencia(responseData.resultado || "✅ Consulta ejecutada correctamente.");
                setQuery("");
            } else {
                setErrorContingencia(responseData.error || "❌ Ocurrió un error en la consulta.");
            }
        } catch (error) {
            setErrorContingencia("⚠️ Error en la conexión con el servidor.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="contenedor-operaciones-sql">
            <h2 className="titulo-operaciones">Consulta SQL</h2>

            {/* Textarea para ingresar consulta SQL */}
            <textarea
                className="input-columnas"
                placeholder="UPDATE tabla SET campo = valor WHERE campo = valor"
                value={query}
                onChange={handleChangeQuery}
            />

            {/* Botón para ejecutar consulta con deshabilitación según estado */}
            <button
                className="btn-ejecutar"
                onClick={handleEjecutar}
                disabled={!query.trim() || loading}
            >
                {loading ? "Ejecutando..." : "Ejecutar Consulta"}
            </button>
        </div>
    );
};

export default Contingencia;
